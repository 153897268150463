import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../../components';
import { Sections } from '../../components/Sections/Sections';
import { Hero } from '../../components/Hero/Hero';
import { SectionData } from '../../components/Sections/Sections.types';
import { PageData } from '../../types';
import { slugify } from '../../util/slugify';

export interface DirectusPageData {
  directus: {
    page: PageData;
  };
}

export interface PageProps {
  data: DirectusPageData;
}

const selectPageData = (data: DirectusPageData) => data.directus.page;
const selectSections = (data: DirectusPageData) =>
  data.directus.page.sections.map(({ item, collection }) => ({ ...item, collection }));

const Page: FC<PageProps> = ({ data }) => {
  const sections = selectSections(data);
  const pageData = selectPageData(data);

  return (
    <Layout pageData={pageData} metadata={{ bodyClassName: `page page-${slugify(pageData.url)}` }}>
      <Hero data={pageData} />
      <Sections sections={sections as SectionData[]} />
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: ID!) {
    directus {
      page: page_by_id(id: $id) {
        ...PageFields
      }
    }
  }
`;
